<template>
  <div class="row terms-conditions-container pt-5 mx-auto">
    <div class="col-12">
      <h2 class="montserrat-header-large text-green text-center">Terms & Conditions</h2>
      
      <h4 class="montserrat-large text-green">Conditions of Use</h4>
      <p class="montserrat-paragraph text-green">We will provide their services to you, which are subject to the conditions stated below in this document. Every time you visit this website, use its services or make a purchase, you accept the following conditions. This is why we urge you to read them carefully.</p>
      
      <h4 class="montserrat-large text-green">Privacy Policy</h4>
      <p class="montserrat-paragraph text-green">Before you continue using our website, we advise you to read our privacy policy regarding our user data collection. It will help you better understand our practices.</p>

      <h4 class="montserrat-large text-green">Copyright</h4>
      <p class="montserrat-paragraph text-green">Content published on this website (digital downloads, images, texts, graphics, logos) is the property of Get Blaked and its content creators and protected by international copyright laws. The entire compilation of the content found on this website is the exclusive property of Get Blaked, with copyright authorship for this compilation by Get Blaked.</p>

      <h4 class="montserrat-large text-green">Communications</h4>
      <p class="montserrat-paragraph text-green">The entire communication with us is electronic. Every time you send us an email or visit our website, you are going to be communicating with us. You hereby consent to receive communications from us. If you subscribe to the news on our website, you are going to receive regular emails from us. We will continue to communicate with you by posting news and notices on our website and by sending you emails. You also agree that all notices, disclosures, agreements and other communications we provide to you electronically meet the legal requirements that such communications be in writing.</p>

      <h4 class="montserrat-large text-green">Applicable Law</h4>
      <p class="montserrat-paragraph text-green">By visiting this website, you agree that the laws of the United States of America, without regard to principles of conflict laws, will govern these terms of service, or any dispute of any sort that might come between Get Blaked and you, or its business partners and associates.</p>
      <p class="montserrat-paragraph text-green">All customers must be 18+ years of age in order to legally purchase CBD products.</p>

      <h4 class="montserrat-large text-green">FDA DISCLAIMER:</h4>
      <p class="montserrat-paragraph text-green">The statements made regarding these products have not been evaluated by the Food and Drug Administration. The efficacy of these products has not been confirmed by FDA-approved research. These products are not intended to diagnose, treat, cure or prevent any disease. All information presented here is not meant as a substitute for or alternative to information from health care practitioners. Please consult your health care professional about potential interactions or other possible complications before using any product. The Federal Food, Drug, and Cosmetic Act require this notice.</p>

      <h4 class="montserrat-large text-green">Disputes</h4>
      <p class="montserrat-paragraph text-green">Any dispute related in any way to your visit to this website or to products you purchase from us shall be arbitrated by state or federal court and you consent to exclusive jurisdiction and venue of such courts.</p>

      <h4 class="montserrat-large text-green">Comments, Reviews, and Emails</h4>
      <p class="montserrat-paragraph text-green">Visitors may post content as long as it is not obscene, illegal, defamatory, threatening, infringing of intellectual property rights, invasive of privacy or injurious in any other way to third parties. Content has to be free of software viruses, political campaign, and commercial solicitation. We reserve all rights (but not the obligation) to remove and/or edit such content. When you post your content, you grant Get Blaked non-exclusive, royalty-free and irrevocable right to use, reproduce, publish, modify such content throughout the world in any media.</p>

      <h4 class="montserrat-large text-green">License and Site Access</h4>
      <p class="montserrat-paragraph text-green">We grant you a limited license to access and make personal use of this website. You are not allowed to download or modify it. This may be done only with written consent from us. We reserve all rights to edit or remove content and cancel orders at our sole discretion.</p>

      <h2 class="montserrat-header-large text-green text-center">Privacy Policy</h2>
      <p class="montserrat-paragraph text-green">We recognize that the information being held by Get Blaked is sensitive and it is treated as such. With user privacy and security in mind, we created the following principles for Get Blaked.</p>
      <ul>
        <li class="montserrat-paragraph text-green">We will never sell or rent your personally identifiable information to third parties for marketing purposes</li>
        <li class="montserrat-paragraph text-green">We will never share your contact information with another user without consent</li>
        <li class="montserrat-paragraph text-green">All personally identifiable information you provide will be secured using industry standard protocols and technology</li>
        <li class="montserrat-paragraph text-green">Users have the ability to change what is public but the default setting should err on the side of less public disclosure rather than more</li>
      </ul>

      <h4 class="montserrat-large text-green">Personal Information Collected</h4>
      <p class="montserrat-paragraph text-green">The primary method of collecting personal information is through forms and the registration process. Get Blaked requires a large amount of personal information during the purchasing process in order to ensure the user's information is accurate and allowing Get Blaked to verify the information when necessary. By default, this information is not displayed, however users above the age of 18 can elect to make some of their personal information public.</p>

      <h4 class="montserrat-large text-green">Cookies</h4>
      <p class="montserrat-paragraph text-green">Like most web sites, Get Blaked uses cookies and web log files to track site usage. A cookie is a tiny data file which resides on your computer which allows Get Blaked to recognize you as a user when you return to our site using the same computer and web browser. Unfortunately, if your browser settings do not allow cookies, you will not be able to use our website. Like the information you enter at purchase, cookie and log file data is used to customize your experience on the web site. We use cookies to improve the quality of our service by storing user preferences and tracking user trends. In the course of serving advertisements or optimizing services to our users, we may allow authorized third parties to place or recognize a unique cookie on your browser. Any information provided to third parties through such cookies will not be personally identifiable but may provide general segment information, e.g. your industry or geographic location, for greater customization of your user experience, Most browsers are initially set up to accept cookies, but you can reset your browser to refuse all cookies or to indicate when a cookie is being sent. Unfortunately, if your browser settings do not allow cookies or you opt to refuse all cookies, you may not be able to use our website or services. Get Blaked does not store personally identifiable information in the cookies.</p>

      <h4 class="montserrat-large text-green">Log files, IP addresses and information about your computer</h4>
      <p class="montserrat-paragraph text-green">Due to the communications standards on the Internet, when you visit the Get Blaked website we automatically receive the URL of the site from which you came and the site to which you are going when you leave Get Blaked. We also receive the Internet protocol (IP) address of your computer (or the proxy server you use to access the World Wide Web), your computer operating system and type of web browser you are using, email patterns, as well as the name of your ISP. This information is used to analyze overall trends to help us improve the Get Blaked service. The linkage between your IP address and your personally identifiable information is never shared with third-parties without your permission or except when required by law.</p>

      <h4 class="montserrat-large text-green">Legal Disclaimer</h4>
      <p class="montserrat-paragraph text-green">It is possible that we may need to disclose personal information when required by law. We will disclose such information wherein we have a good-faith belief that it is necessary to comply with a court order, ongoing judicial proceeding, or other legal process served on our company or to exercise our legal rights or defend against legal claims.</p>

      <h4 class="montserrat-large text-green">Disclosures to others</h4>
      <p class="montserrat-paragraph text-green">We may also disclose your personal and other information you provide, to another third party as part of a reorganization or a sale of the assets of a Get Blaked company and only after taking steps to ensure that your privacy rights continue to be protected. Any third party to which we transfer or sell Get Blaked's assets will have the right to continue to use the personal and other information that you provide to us.</p>

      <h4 class="montserrat-large text-green">Changes to this Privacy Policy</h4>
      <p class="montserrat-paragraph text-green">Get Blaked may update this privacy policy. In the event there are significant changes in the way we treat your personally identifiable information, we will display a notice on this site. Unless stated otherwise, our current Privacy Policy applies to all information that we have about you and your account. If you continue to use the Get Blaked service after notice of changes have been sent to you or published on our site, you hereby provide your consent to the changed practices.</p>

      <h4 class="montserrat-large text-green">Security</h4>
      <p class="montserrat-paragraph text-green">In order to secure your personal information, access to your data on Get Blaked is password-protected, and sensitive data (such as credit card information) is protected by SSL encryption when it is exchanged between your web browser and our web site. To protect any data you store on our servers we also regularly audit our system for possible vulnerabilities and attacks and we use a tier-one secured-access data center. It is your responsibility to protect the security of your login information.</p>

      <h2 class="montserrat-header-large text-green text-center">Refund Policy</h2>

      <p class="montserrat-paragraph text-green">If you see something wrong with our product within 24 hours of getting your product, just contact us by email.</p>

    </div>
  </div>
</template>

<script>
export default {
  name: 'TermsConditions'
}
</script>

<style lang="scss" scoped>
.terms-conditions-container {
  max-width: 1400px;
}
</style>